import React from "react";
import { Col, Container, Row } from "reactstrap";

const ExpertiseCard = (props) => {
  return (
    <>
      <Col lg={4} md={6}>
        <div
          className={
            props.isShadow
              ? "service-box text-center mt-4 box-shadow"
              : "service-box text-center mt-4"
          }
        >
          {props.imgsrc ? <img src={props.imgsrc} alt="" className="img-fluid" /> : ""}
          <h5 className="fs-18 mt-4">{props.title}</h5>
          <div className="lighlight-border mt-3"></div>
          <p className="text-muted mt-3">{props.caption}</p>
        </div>
      </Col>
      {/* <!--end col--> */}
    </>
  );
}

// Expertise data

const ExpertiseData = [
  {
    title: "Comprehensive Tech Proficiency",
    caption:
      "A full-stack developer leverages a wide array of technologies across both front-end and back-end development. Proficient in HTML, CSS, JavaScript, and frameworks like React.js, Vue.js, and Angular, they create dynamic and responsive user interfaces. On the server side, they utilize PHP, Laravel, Node.js, and MySQL to build and manage robust back-end systems. Their experience extends to AI integrations, cloud applications (including AWS), and mobile development for both Android and iOS.",
  },
  {
    title: "End-to-End Project Management",
    caption: "Full-stack developers manage all aspects of the development process, from initial concept through to deployment and maintenance. They use GitHub for version control, ensuring seamless collaboration and code management. Their expertise in web design, front-end and back-end development, and web development best practices enables them to deliver comprehensive solutions. Additionally, their skills in client relations and client Expertise ensure effective communication and project alignment with stakeholders."
  },
  {
    title: "Advanced Problem-Solving & Innovation",
    caption: "Equipped with a diverse skill set, full-stack developers excel in troubleshooting and innovating across various technologies. They integrate advanced features using AJAX, jQuery, and TypeScript, and handle complex issues across both client and server sides. Their capability in managing Asterisk (PBX) systems and VoIP solutions further demonstrates their ability to address a wide range of technical challenges, optimizing performance and enhancing user experience."
  },
  {
    title: "User-Centric Front-End Development",
    caption: "Full-stack developers excel in creating user-friendly interfaces that enhance user experience. They employ HTML, CSS, and JavaScript, alongside frameworks such as React.js, Vue.js, and Angular, to design intuitive and responsive web interfaces. Their skills in web design and front-end development ensure that applications are both visually appealing and highly functional, providing seamless interactions and accessibility across various devices and screen sizes."
  },
  {
    title: "Robust Back-End Data Security",
    caption: "In back-end development, full-stack developers prioritize data security and integrity. They implement secure coding practices using PHP, Laravel, Node.js, and MySQL to safeguard sensitive information and protect against vulnerabilities. By leveraging best practices in database management, encryption, and secure API design, they ensure that applications are resilient against security threats and maintain high standards of data protection."
  },
  {
    title: "Sustainable Long-Term Solutions",
    caption: "Full-stack developers focus on building scalable and maintainable solutions that address long-term project needs. They employ cloud technologies (such as AWS) and robust frameworks to ensure that applications can handle future growth and evolving requirements. By incorporating modular design principles and following industry best practices, they create systems that are not only efficient but also adaptable to changing technology landscapes and business objectives."
  }
];

const Expertise = () => {
  return (
    <>
      <section className="section" id="expertise">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}> 
              <div className="header-title text-center">
                <h3>Expertise & Proficiencies</h3>
                <div className="title-border mt-3"></div>
                <p className="text-muted mt-3">
                  As a Full-Stack engineer, there is no limit to what can be curated just for you or your company
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            {/* Expertise Component Start */}

            {ExpertiseData.map((value, idx) => (
              <ExpertiseCard
                key={idx}
                imgsrc={value.imgsrc}
                title={value.title}
                caption={value.caption}
                isShadow={value.isShadow}
              />
            ))}

            {/* Expertise Component End */}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Expertise;
