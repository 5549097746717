import React from "react";

import About from "../components/About";
import BackToTop from "../components/BackToTop";
import Contact from "../components/Contact";
import Footer from "../Layout/Footer";
import FooterAlt from "../Layout/FooterAlt";
import Expertise from "../components/Expertise";


const Layout = (props) => {
  return (
    <React.Fragment>
      {props.children}
      <About />
      <Expertise />
      <Contact />
      <Footer />
      <FooterAlt />
      <BackToTop />
    </React.Fragment>
  );
};


export default Layout;
