import React from "react";

import about from "../assets/images/logo.png";
import { Container, Row, Col } from "reactstrap";

const About = () => {
  return (
    <React.Fragment>
      <section id="about" className="section bg-light">
        <Container>
          <Row className="align-items-center g-3 g-lg-0">
            <Col lg={6}>
              <div className="skill-box bg-white p-4 rounded box-shadow">
                <p className="text-uppercase mb-1">Who am I?</p>
                <p className="text-muted">
                UI-oriented, up-to-date full stack developer with 7+ years of extensive professional experience (10+ in general development) in designing, developing, and implementing software solutions. Adept at creating user-friendly interfaces and robust backend systems, ensuring seamless communication and high performance.  Experienced in leading development teams, mentoring junior developers, and training new employees to uphold company standards.
                </p>
              </div>
            </Col>
            <Col className="about-img" lg={6}>
              <img
                src={about}
                className="img-fluid about-image rounded box-shadow"
                alt=""
              />
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default About;
