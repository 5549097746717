import React from "react";
import logolight from "../assets/images/logo.png";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

const ListCard = (props) => {
  return (
    <React.Fragment>
      <div className={props.isList ? "col-lg-3 col-md-6" : "col-lg-2 col-md-6"}>
        <div className={props.isShow ? "mt-4 ps-0 ps-lg-5" : "mt-4"}>
          <h6 className="text-dark text-uppercase fs-16">{props.title}</h6>
          <ul className="list-unstyled footer-link mt-3 mb-0">
            {props.isChildList.map((Item, key) => (
              <li key={key}>
                <Link to="/#" alt="work" className="text-dark">
                  {Item.subList}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
}

const ListIconData = [
  {
    iconClass: "mdi mdi-facebook",
  },
  {
    iconClass: "mdi mdi-linkedin",
  },
  {
    iconClass: "mdi mdi-pinterest",
  },
  {
    iconClass: "mdi mdi-twitter",
  },
];

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="bg-footer">
        <Container>
          <Row>
            <Col lg={3}>
              <div>
                <Link to="/">
                  <img src={logolight} alt="" height="22" />
                </Link>
                <p className="text-white-50 mt-3 pt-2 mb-0 ">
                Copyright {new Date().getFullYear()} © Nick Stennes | All Rights Reserved.
                </p>
              </div>
            </Col>
            <Col />
            <Col>
              
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
}

export default Footer;
