import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const Section = () => {
  return (
    <React.Fragment>
      {/* <!-- START HOME --> */}
      <section className="bg-home" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="home-content me-lg-5">
                <h6 className="sub-title mb-3 text">Nick Stennes</h6>
                <h1 className="mb-4">
                Experienced Full Stack Software Engineer
                </h1>
                <p className="fs-17">
                Over 10 years of Creating End-to-End Client Web Needs & Building User Interface and Experience forward solutions
                </p>
                <div className="mt-4">
                  <a href="#contact" className="btn btn-primary mt-2">
                    Submit An Inquiry
                  </a>{" "}
                  <a href="#expertise" className="btn btn-outline-dark mt-2 ms-md-1">
                    Learn More
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <!-- END HOME --> */}
      {/* <!-- START SHAPE --> */}
      <div className="position-relative">
        <div className="shape">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="1440"
            height="150"
            preserveAspectRatio="none"
            viewBox="0 0 1440 150"
          >
            <g mask='url("#SvgjsMask1022")' fill="#4e8bb5FF">
              <path
                d="M 0,58 C 144,73 432,131.8 720,133 C 1008,134.2 1296,77.8 1440,64L1440 250L0 250z"
                fill="#4e8bb5FF"
              ></path>
            </g>
            <defs>
              <mask id="SvgjsMask1022">
                <rect width="1440" height="250" fill="#4e8bb5FF"></rect>
              </mask>
            </defs>
          </svg>
        </div>
      </div>
      {/* <!-- END SHAPE --> */}
    </React.Fragment>
  );
};

export default Section;
