import React, { useState, useEffect } from "react";
import { Collapse, Container, NavbarToggler, NavLink } from "reactstrap";
import { Link } from "react-router-dom";

//import images
import logoLight from "../assets/images/logo.png";
import logoDark from "../assets/images/logo.png";

const NavBar = ({ isDark }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [navClass, setNavClass] = useState("");
  const [activeSection, setActiveSection] = useState("");

  const toggle = () => setIsOpenMenu(!isOpenMenu);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  function handleScroll() {
    var scrollPosition = window.scrollY;

    if (scrollPosition > 50) {
      setNavClass("nav-sticky");
    } else {
      setNavClass("");
    }

    const sections = ["home", "about", "expertise", "contact"];
    sections.forEach((section) => {
      const element = document.getElementById(section);
      if (element && element.offsetTop <= scrollPosition + 100) {
        setActiveSection(section);
      }
    });
  }

  const isDarkClass = isDark ? "navbar-dark" : "navbar-light";

  return (
    <React.Fragment>
      <nav
        className={
          "navbar navbar-expand-lg fixed-top " +
          isDarkClass +
          " sticky " +
          navClass
        }
        id="navbar"
      >
        <Container>
          <Link className="navbar-brand logo text-uppercase" to="/">
            {isDark ? (
              <>
                <img
                  src={logoDark}
                  className="logo-light"
                  alt=""
                  height="22"
                />
                <img
                  src={logoDark}
                  className="logo-dark"
                  alt=""
                  height="22"
                />
              </>
            ) : (
              <>
                <img
                  src={logoLight}
                  className="logo-light"
                  alt=""
                  height="22"
                />
                <img
                  src={logoDark}
                  className="logo-dark"
                  alt=""
                  height="22"
                />
              </>
            )}
          </Link>
          <NavbarToggler
            className="navbar-toggler"
            type="button"
            onClick={toggle}
          >
            <i className="mdi mdi-menu"></i>
          </NavbarToggler>
          <Collapse
            isOpen={isOpenMenu}
            className="navbar-collapse"
            id="navbarNav"
          >
            <ul className="navbar-nav ms-auto navbar-center">
              <li className="nav-item">
                <NavLink
                  href="#home"
                  className={`nav-link ${activeSection === "home" ? "active" : ""}`}
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  href="#about"
                  className={`nav-link ${activeSection === "about" ? "active" : ""}`}
                >
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  href="#expertise"
                  className={`nav-link ${activeSection === "expertise" ? "active" : ""}`}
                >
                  Expertise
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  href="#contact"
                  className={`nav-link ${activeSection === "contact" ? "active" : ""}`}
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </Collapse>
        </Container>
      </nav>
    </React.Fragment>
  );
};

export default NavBar;
